// eslint-disable-next-line no-unused-vars

import axios from 'axios'
import AxiosConfig from '@/utils/axiosConfig'
import api from '@/utils/api'

import {
  NFT_REQUEST,
} from './action'

const state = {
  nftList: [],
  pageInfo: null,
}

const getters = {
  getNftList: (state) => {
    return state.nftList
  },
}

const actions = {
  [NFT_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}nft-latest-sales-summary/`,
        { params: payload },
        AxiosConfig(),
      ).then((res) => {
        commit('SET_NFTS', res.data)
      }).catch((err) => {
        throw new Error(err)
      })
  },
}

const mutations = {
  SET_NFTS: (state, resp) => {
    state.nftList = resp
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
