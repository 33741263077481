import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'

Vue.use(Vuex)

import overview from './overview'
import account from './account'
import currency from './currency'
import compare from './compare'
import explorer from './explorer'
import nft from './nft'

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [pathify.plugin],
  modules: {
    overview,
    account,
    currency,
    compare,
    explorer,
    nft,
  },
  state: {
    loader: false,
    fiat: 'USD',
    fiatCollection: [
      { value: 'USD', currency: 'USD' },
      { value: 'EUR', currency: 'EUR' },
      { value: 'INR', currency: 'INR' },
    ],
    searchDialog: false,
  },
  getters: {
    loading: (state) => state.loader,
    getGlobalFiat: (state) => state.fiat,
    getGlobalFiatCollection: (state) => state.fiatCollection,
  },
  mutations: {
    SHOW_LOADER(state, resp) {
      state.loader = resp
    },
    SET_GLOBAL_FIAT(state, resp) {
      state.fiat = resp.toUpperCase()
    },
    SET_SEARCH_DIALOG(state, resp) {
      state.searchDialog = resp
    },
  },
  actions: {},
})
