// eslint-disable-next-line no-unused-vars

import axios from 'axios'
import AxiosConfig from '@/utils/axiosConfig'
import * as _axiosConfig from '@/utils/axiosConfig'
import api from '@/utils/api'

import {
  CURRENCY_REQUEST,
  CURRENCY_HISTORY_REQUEST,
  CURRENCY_ACTIVITY_REQUEST,
  USER_CURRENCY_RATINGS_REQUEST,
  POST_CURRENCY_RATINGS_REQUEST,
  CURRENCY_RATINGS_REQUEST,
} from './action'

let source, cancelToken

const state = {
  currency: {},
  history: [],
  label: [],
  volume: [],
  marketCap: [],
  data: [],
  activity: {},
  average_rating: null,
  ratings: [],
  notificationDetail: {
    message: '',
    pageDetails: '',
    apidetails: '',
    notificationType: '',
    isNotification: false,
    timeout: '2000',
  },
}

const getters = {
  getCurrency: (state) => {
    return state.currency
  },
  getHistory: (state) => {
    return state.history
  },
  getData: (state) => {
    return {
      name: state.name,
      label: state.label,
      data: state.data,
      volume: state.volume,
      marketCap: state.marketCap,
    }
  },
  getActivity: (state) => {
    return state.activity
  },
  getRatings: (state) => {
    return state.ratings
  },
  getAverageRating: (state) => {
    return state.average_rating
  },
}

const actions = {
  [CURRENCY_REQUEST]: async ({ commit }, payload) => {
    commit('CANCEL_REQUEST')
    await axios
      .get(`${api.apiUrl}coin-info/`,
        { params: payload },
        AxiosConfig(),
      ).then((res) => {
        commit('SET_CURRENCY', res.data && res.data[0])
      })
      .catch((err) => {
        throw new Error(err)
      })
  },
  [CURRENCY_HISTORY_REQUEST]: async ({ commit }, payload) => {
    await axios
      .get(
        `${api.apiUrl}history-prices/`,
        { params: payload, cancelToken },
        AxiosConfig(),
      )
      .then((res) => {
        commit('SET_HISTORY', res.data)
        commit('SET_CHART_DATA', { type: 'currency', data: res.data })
      })
      .catch((err) => {
        throw new Error(err)
      })
  },
  [CURRENCY_ACTIVITY_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}latest-chain-activity/`,
        { params: payload },
        AxiosConfig(),
      ).then((res) => {
        const label = [], data = [], price = [], cap = []

        res.data.forEach((o, idx) => {
          if (idx < Object.keys(res.data).length - 1) {
            label.push(o.trans_date)
            data.push(o.trans_count)
          }
        })

        commit('SET_ACTIVITY', { label: label, data: data })
      }).catch((err) => {
        throw new Error(err)
      })
  },
  [USER_CURRENCY_RATINGS_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}get-user-review/`,
        { params: payload },
        _axiosConfig.AxiosLoggedInConfig(),
      ).then((res) => {
        commit('SET_RATINGS', res.data)
      }).catch((err) => {
        throw new Error(err)
      })
  },
  [POST_CURRENCY_RATINGS_REQUEST]: async ({ commit }, payload) => {
    const qryStr = `?coin_id=${payload.coin_id}&rating=${payload.rating}&technology=0&ease_of_use=0&usage_fee=0&cust_service=0&progress=0`

    return await axios
      .post(
        `${api.apiUrl}create-review/${qryStr}`,
        _axiosConfig.AxiosLoggedInConfig(),
      )
      .then((res) => {
        const notificationDetail = {
          message: res.data.detail,
          pageDetails: 'Currency',
          apidetails: 'POST_CURRENCY_RATINGS',
          notificationType: 'Success',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)

        return res.data
      })
      .catch((err) => {
        return err
      })
  },
  [CURRENCY_RATINGS_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}get-coin-review/`,
        { params: payload },
        _axiosConfig.AxiosLoggedInConfig(),
      ).then((res) => {
        commit('SET_AVERAGE_RATING', res.data.average_rating)
      }).catch((err) => {
        throw new Error(err)
      })
  },

}

const mutations = {
  CANCEL_REQUEST() {
    if (source) {
      source.cancel()
      source = null
    }
    source = axios.CancelToken.source()
    cancelToken = source.token
  },
  SET_CURRENCY: (state, resp) => {
    state.currency = resp
  },
  SET_RATINGS: (state, resp) => {
    state.ratings = resp
  },
  SET_AVERAGE_RATING: (state, resp) => {
    state.average_rating = resp
  },
  SET_HISTORY: (state, resp) => {
    state.history = resp
  },
  SET_ACTIVITY: (state, resp) => {
    state.activity = resp
  },
  SET_MESSAGE(state, resp) {
    state.notificationDetail = resp
  },
  SET_CHART_DATA: (state, params) => {
    state.label = []
    state.data = []
    state.volume = []
    state.marketCap = []
    state.name = ''
    state.symbol = ''

    params.data.forEach((o, idx) => {
      state.label.push(
        `${o.load_date_time.slice(0, 10)} ${o.load_date_time.slice(11, 19)}`,
      )

      // const price =
      //   parseFloat(o.price) < 1000
      //     ? parseFloat(o.price).toFixed(4)
      //     : parseFloat(o.price).toFixed(2)

      state.data.push(o.price)
      state.volume.push(o.volume_24h)
      state.marketCap.push(o.market_cap)
      state.name = o.name
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
