import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
  icons: { iconfont: 'mdi' },

  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: '#e3e6f5', //#f2f6fa
        common_background: '#FFFFFF',
        primary: '#1D8DF5',
        primary_1: '#2D3745',
        primary_2: '#515F70',
        primary_3: '#878F9A',
        secondary: '#000',
        secondary_1: '#FFAC4B',
        secondary_2: '#FF5050',
        secondary_3: '#54CC8B',
        shade_c1: '#F5EFE0',
        shade_c2: '#DEF5FF',
        shade_c3: '#FFECEB',
        shade_c4: '#DBF5E7',
        shade_c5: '#E8EFF6',
        shade_c6: '#F2F6FA',
        accent: '#fbb03b',
        grey: '#515F70',
        horizontal_divider: '#C9D6E7',
        error: '#b71c1c',
        footer_text_input: '#3B4656',
        white: '#fff',
        black: '#000',
        card_bg: '#fff',
        common_text: '#96A5BB',
      },
      dark: {
        background: '#2D3745',
        common_background: '#1D2734',
        primary: '#1D8DF5',
        primary_1: '#2D3745',
        primary_2: '#515F70',
        primary_3: '#878F9A',
        secondary: '#fff',
        secondary_1: '#FFAC4B',
        secondary_2: '#FF5050',
        secondary_3: '#54CC8B',
        shade_c1: '#F5EFE0',
        shade_c2: '#DEF5FF',
        shade_c3: '#FFECEB',
        shade_c4: '#DBF5E7',
        shade_c5: '#E8EFF6',
        shade_c6: '#F2F6FA',
        accent: '#fbb03b',
        grey: '#96A5BB',
        horizontal_divider: '#515F70',
        error: '#b71c1c',
        footer_text_input: '#3B4656',
        white: '#fff',
        black: '#000',
        card_bg: '#101D2D',
        common_text: '#96A5BB',
      },
    },
  },
}

export default new Vuetify(opts)
