<template>
  <v-menu offset-y>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        block
        :class="`
          ${buttonSize}
          float-right
          no-hover-bg
        `"
        :disabled="isDisabled"
        v-bind="attrs"
        v-on="on"
      >
        <span>{{ selectedText }}</span>
        <v-icon small class="ml-1 icon-arrow-head-down" />
      </v-btn>
    </template>

    <v-list v-if="listItems.length < 10" class="pa-0">
      <v-list-item-group>
        <v-list-item
          v-for="item in listItems"
          :key="keyItem && keyItem !== '' ? item[keyItem]: item"
          :input-value="item['selected']"
          class="px-2"
          link
          @click="$emit('click', item)"
        >
          <v-list-item-title :class="`${ hasCheckBox ? '' : 'text-center'} `">
            <v-checkbox
              v-if="hasCheckBox"
              :v-model="item['selected']"
              :input-value="item['selected']"
              class="ma-0 pa-0 f-12"
              hide-details=""
              :label="keyItem && keyItem !== '' ? item[keyItem]: item"
            />
            <span v-else>
              {{ keyItem && keyItem !== '' ? item[keyItem]: item }}
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list v-else class="column-wrapper">
      <div
        v-for="item in listItems"
        :key="keyItem && keyItem !== '' ? item[keyItem]: item"
        class="custom-list"
        @click="$emit('click', item)"
      >
        <v-checkbox
          v-if="hasCheckBox"
          :v-model="item['selected']"
          :value="item['selected']"
          class="ma-0 pa-0"
          hide-details=""
          :label="keyItem && keyItem !== '' ? item[keyItem]: item"
        />
        <span v-else>
          {{ keyItem && keyItem !== '' ? item[keyItem]: item }}
        </span>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    listItems: { type: Array, default: () => [] },
    keyItem: { type: String, default: () => '' },
    selectedText: { type: String, default: () => '' },
    hasCheckBox: { type: Boolean, default: () => false },
    buttonSize: { type: String, default: () => 'small' },
    isDisabled: { type: Boolean, default: () => false },
  },
}
</script>

<style lang="scss" scoped>
button.v-btn.ellipsis {
  &:hover,
  &:focus {
    span .v-btn__content > i.chev {
      display: flex !important;
    }
  }

  max-width: auto;
  span.v-btn__content {
    max-width: 80px !important;
    > span {
      font-weight: 600;
      max-width: 75px;
      overflow: hidden;
      letter-spacing: 0.3px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i.chev {
      display: none !important;
      width: 15px;
      margin-left: 5px;

      @media (min-width: 1200px) {
        display: flex !important;
      }
    }
  }
}

.theme--dark {
  &.column-wrapper {
    .custom-list {
      &:hover {
        background-color: rgba(white, 0.08);
      }
    }
  }
}

.theme--light {
  &.column-wrapper {
    .custom-list {
      &:hover {
        background-color: rgba(black, 0.03);
      }
    }
  }
}

.column-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  max-width: 600px;
  height: auto;
  padding: 5px;
  text-align: center;
  z-index: 10;

  @media (max-width: 500px) {
    max-width: 100vw;
  }

  .custom-list {
    cursor: pointer;
    display: block;
    min-width: 200px;
    padding: 5px;

    @media (max-width: 500px) {
      width: 50%;
      padding: 10px 0;
    }
  }
}
</style>
