export const GET_FIREBASE_IDTOKEN_REQUEST = 'GET_FIREBASE_IDTOKEN_REQUEST'
export const FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD  = 'FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD'
export const FIREBASE_CREATEUSER_WITH_EMAIL_PASSWORD = 'FIREBASE_CREATEUSER_WITH_EMAIL_PASSWORD'
export const LOGOUT = 'LOGOUT'
export const LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN'
export const FIREBASE_SIGNIN_WITH_GOOGLE  = 'FIREBASE_SIGNIN_WITH_GOOGLE'
export const FIREBASE_SEND_VERIFICATION_EMAIL = 'FIREBASE_SEND_VERIFICATION_EMAIL'
export const FIREBASE_SEND_PASSWORD_RESET_EMAIL = 'FIREBASE_SEND_PASSWORD_RESET_EMAIL'
export const SUBSCRIBE_USER = 'SUBSCRIBE_USER'
export const UNSUBSCRIBE_USER = 'UNSUBSCRIBE_USER'
export const CONFIRMSUBSCRIPTION = 'CONFIRMSUBSCRIPTION'
