<template>
  <div class="header-container">
    <div class="main-header max-width d-flex d-md-none" dense>
      <v-app-bar
        flat
        height="70"
        class="pa-0 ma-0"
        dense
        dark
        align="center"
        width="100%"
      >
        <div class="header-small fill-width">
          <div class="logo d-inline-flex mr-auto">
            <a href="/">
              <img
                src="@/assets/images/logo.png"
                lazy-src="@/assets/images/logo.png"
                alt="BlockchainMetrics"
              />
            </a>
          </div>
          <div>
            <v-btn
              icon
              large
              class="no-background btn-drawers white--text"
              @click="openSearchDialog"
            >
              <v-icon class="icon-search1"/>
            </v-btn>
          </div>
          <div>
            <v-btn
              icon
              large
              class="no-background btn-drawers white--text"
              @click="drawer = true"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </div>
        </div>
      </v-app-bar>
    </div>

    <LatestGlobalMetrics :is-logged-in="isLoggedIn" @logout="logout"/>

    <div class="nav-wrapper">
      <div class="main-header max-width d-none d-md-flex" dense>
        <v-app-bar
          ref="navbar"
          flat
          class="pa-0 ma-0"
        >
          <v-row dense class="ma-0 pa-0" align="center">
            <v-col sm="3">
              <v-toolbar-title class="logo white--text pl-0">
                <a href="/">
                  <img
                    src="@/assets/images/logo.png"
                    lazy-src="@/assets/images/logo.png"
                    alt="BlockchainMetrics"
                  />
                </a>
              </v-toolbar-title>
            </v-col>

            <v-col sm="9" >
              <v-row dense align="center">
                <v-col cols="9" md="8" lg="9">
                  <div class="d-none d-sm-flex float-right">
                    <v-list nav class="d-inline-flex ">
                      <v-list-item
                        v-for="link in navigationLinks"
                        :key="link.name"
                        :class="`${ path === link.path ? 'active': ''}`"
                        :ripple="false"
                        dense
                        @click="gotoUrl(link.path)"
                      >
                        <v-icon :class="`${link.classIcon}`"/>
                        <span
                          class=""
                          :class="`${ path === link.path ? 'd-flex': 'd-none d-lg-flex'}`"
                        >
                          {{ link.name }}
                        </span>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-col>
                <v-col cols="3" md="4" lg="3">
                  <GlobalSearch :input-id="`searchMedium`" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-app-bar>
      </div>
    </div>

    <div id="toTop">
      <v-btn
        v-show="fab"
        v-scroll="onScroll"
        color="secondary_3"
        dark
        absolute
        top
        right
        fab
        small
        @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>

    <v-dialog
      v-model="dialogSearch"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="common_background">
        <v-row dense class="pa-3">
          <v-col class="flex-grow-1">
            <GlobalSearch :input-id="`searchSmall`" />
          </v-col>
          <v-col class="flex-grow-0">
            <v-btn
              icon
              large
              class="float-right no-background"
              @click="closeSearchDiaglog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="drawer"
      class="d-md-none common_background"
      fixed
      temporary
      width="100%"
    >
      <v-list class="pa-0" nav two-line>
        <v-list-item-group
          v-model="group"
        >
          <v-toolbar-title class="white--text main-header pa-0">
            <div class="header-small fill-width">
              <div class="logo d-inline-flex mr-auto">
                <a href="/">
                  <img
                    src="@/assets/images/logo.png"
                    lazy-src="@/assets/images/logo.png"
                    alt="BlockchainMetrics"
                  />
                </a>
              </div>
              <div>
                <v-btn
                  icon
                  class="no-background btn-drawers white--text"
                  @click="onChangeColor"
                >
                  <v-icon :class="`icon-${ this.$vuetify.theme.dark ? 'night': 'day'} theme-switch`" />
                </v-btn>
              </div>
              <div>
                <v-btn
                  icon
                  class="no-background btn-drawers white--text"
                  @click="drawer = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </v-toolbar-title>
          <v-divider />
          <v-list-item
            v-for="link in navigationLinks"
            :key="link.name"
            :class="`px-4 ${ path === link.path ? 'v-item--active v-list-item--active': ''}`"
            :ripple="false"
            dense
            @click="gotoUrl(link.path)"
          >
            <v-icon :class="`mr-2 ${link.classIcon}`"/><span>{{ link.name }}</span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-row class="pa-4">
        <v-col>
          <Menu
            :button-size="`primary no-hover-bg v-size--large`"
            :selected-text="selected_currency"
            :list-items="currencies"
            key-item="currency"
            @click="onSelectCurrencyMenu($event)"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            large
            primary
            block
            color="secondary_3"
            class="white--text v-btn--radius"
            @click="drawer = false; gotoUrl('/account/login')"
          >
            {{ `${$t("Login")}` }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            large
            block
            class="primary white--text text-capitalize"
            @click="signinwithGoogle"
          >
            <v-icon left> mdi-google </v-icon> {{ $t('SignInGoogle') }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            large
            block
            class="primary white--text text-capitalize"
            :to="{ path: '/account/register' }"
          >
            {{ $t('SignUp') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD,
  FIREBASE_SIGNIN_WITH_GOOGLE,
  FIREBASE_SIGNIN_WITH_TWITTER,
  FIREBASE_SEND_VERIFICATION_EMAIL,
  LOGOUT,
} from '@/store/account/action'
import { get, call, dispatch, commit } from 'vuex-pathify'
import firebase from 'firebase'
import LatestGlobalMetrics from '@/components/shared/LatestGlobalMetrics'
import GlobalSearch from '@/components/shared/GlobalSearch'
import Menu from '@/components/atoms/Menu'

export default {
  name: 'NavBar',
  components: {
    LatestGlobalMetrics,
    GlobalSearch,
    Menu,
  },
  data: function () {
    return {
      path: '/',
      drawer: false,
      group: null,
      isLoggedIn: false,
      currentUser: false,
      moveToDown: 0,
      fab: false,
      globalSearchVisibility: true,
      selected_currency: 'USD',
      selected_nav: { name: this.$i18n.t('Home'), classIcon: 'icon-home1', path: '/' },
      navigationLinks: [
        { name: this.$i18n.t('Home'), classIcon: 'icon-home1', path: '/' },
        { name: this.$i18n.t('Chains'), classIcon: 'icon-activity1', path: '/chain-activity' },
        { name: this.$i18n.t('Dapps'), classIcon: 'icon-activity3', path: '/dapp-activity' },
        { name: this.$i18n.t('NFTs'), classIcon: 'icon-activity2', path: '/nfts' },
        { name: this.$i18n.t('Explorer'), classIcon: 'icon-explore', path: '/explorer' },
        { name: this.$i18n.t('Compare'), classIcon: 'icon-compare', path: '/compare' },
        { name: this.$i18n.t('Analytics'), classIcon: 'icon-analytics', path: '/analytics' },
        { name: this.$i18n.t('News'), classIcon: 'icon-news', path: '/news' },
        { name: this.$i18n.t('Blog'), classIcon: 'icon-blog', path: '/blog' },
        { name: this.$i18n.t('About'), classIcon: 'icon-info', path: '/about' },
      ],
    }
  },
  computed: {
    dialogSearch: {
      get: get('searchDialog'),
      set: (value) => commit('SET_SEARCH_DIALOG', value),
    },
    currencies: get('getGlobalFiatCollection'),
  },
  watch: {
    '$route.path': {
      handler: function (path) {
        this.path = path
        this.isLoggedIn = localStorage.getItem('token') !== null ? true : false
        this.currentUser = firebase.auth().currentUser?.email
      },
      deep: true,
      immediate: true,
    },
    '$store.state.fiat': {
      async handler(value) {
        this.selected_currency = value
      },
      deep: true,
    },
  },
  beforeRouteAfter(to, from, next) {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true
      this.currentUser = firebase.auth().currentUser.email
    }
    next()
  },
  methods: {
    ...call('account',['LOGOUT']),
    openSearchDialog() {
      this.dialogSearch = true
    },
    closeSearchDiaglog() {
      this.dialogSearch = false
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0

      this.fab = top > 50
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    onChangeColor() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    gotoUrlMenu(item) {
      this.selected_nav = item
      this.gotoUrl(item.path)
    },
    gotoUrl(url) {
      if (url !== this.$route.path) this.$router.push(url)
    },
    onSelectCurrencyMenu(item) {
      commit('SET_GLOBAL_FIAT', item.value)
      this.selected_currency = item.value
      this.drawer = false
    },
    async signinwithGoogle() {
      this.loading = true
      const provider = new firebase.auth.GoogleAuthProvider()

      await dispatch(`account/${FIREBASE_SIGNIN_WITH_GOOGLE}`, provider)
      this.loading = false

      this.$router.replace({ name: 'Home' })
    },
    async logout() {
      this.LOGOUT()
      this.drawer = false
      this.$router.go({ name: 'Home' })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-menu__content--fixed {
  z-index: 100 !important;
}

.theme {
  &--dark {
    .nav-wrapper,
    .main-header {
      background-color: $header_background;
    }
  }
  &--light {
    .nav-wrapper,
    .main-header {
      background: linear-gradient(
        90deg,
        $blue-linear-dark 0%,
        $blue-linear-light 100%
      );
    }
  }
}

.nav-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

header {
  &.v-sheet {
    &.v-toolbar {
      &.v-app-bar {
        background-color: transparent;
      }
      &__content {
        padding: 0 !important;
      }
    }
    &.v-toolbar.theme--light {
      &.v-app-bar {
        background-color: transparent;
      }
    }
  }
  .v-toolbar {
    &__title {
      display: flex;
      align-items: center;
      span {
        font-size: 1.2rem !important;
        font-weight: 600;
        padding-left: 5px;
      }
    }
  }
  .v-list {
    background-color: transparent !important;
    padding: 0;
    &-item {
      border-radius: inherit;
      padding: 0;
      margin: 0 5px;
      min-height: 20px;
      height: 40px;

      @media (max-width: 1399px) {
        margin: 0 5px;
      }

      @media (min-width: 1400px) {
        margin: 0 10px;
      }

      &.active {
        border-bottom: 2px solid $secondary_3;
        span {
          display: block;
          color: $secondary_3 !important;
        }
        i {
          color: $secondary_3 !important;
        }
      }
      &:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: $white !important;
      }
      &:hover {
        &:before {
          opacity: 0;
        }
        cursor: pointer;
      }
      &__title {
        font-weight: 500;
      }
      span {
        font-size: 0.9rem;
        margin-left: 4px;
      }
      i {
        color: $white;
        font-size: 1rem;
      }
    }
  }

  .night {
    position: absolute;
    justify-content: center;
    align-items: center;
    left: calc(100% - 34px);
    top: -6px;
  }
  .day {
    position: absolute;
    justify-content: center;
    align-items: center;
    left: calc(100% - 58px);
    top: -6px;
  }
}

.header-small {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.logo {
  height: 70px;
  a {
    margin: auto 0;
    img {
      width: auto;
      height: 36px;
      display: block;
      image-rendering: -webkit-optimize-contrast;
      @media (max-width: 320px) {
        height: 35px;
      }
    }
  }
}

#toTop {
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 100;
}

span.currency-name {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
