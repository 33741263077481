<template>
  <div>
    <div class="latest-global-m max-width">
      <v-row no-gutters class="py-0 px-1 px-md-5" align="center">
        <v-col cols="12" md="9" lg="10" class="flex-grow-1">
          <v-list dense class="d-inline-flex fill-width">
            <v-list-item class="item-list" @click="gotoUrl(`/global-metrics`, 'marketcap')">
              <v-list-item-title class="text-center secondary--text">
                {{ $t('TotalMarketCap') }}:
                <span class="secondary_3--text">
                  {{ helper.abbreviateNumber(latestGlobalMetrics.total_market_cap) }}
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="item-list" @click="gotoUrl(`/global-metrics`, 'volume')">
              <v-list-item-title class="text-center secondary--text">
                {{ $t('Total24hVol') }}:
                <span class="secondary_3--text">
                  {{ helper.abbreviateNumber(latestGlobalMetrics.total_volume_24h) }}
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="item-list" @click="gotoUrl(`/global-metrics`, 'btcdominance')">
              <v-list-item-title class="text-center secondary--text">
                {{ $t('BTCDominance') }}:
                <span class="secondary_3--text">
                  {{ helper.abbreviateNumber(helper.convertToBigNumber(latestGlobalMetrics.btc_dominance, 2)) }}
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="item-list" @click="gotoUrl(`/global-metrics`, 'ethdominance')">
              <v-list-item-title class="text-center secondary--text">
                {{ $t('ETHDominance') }}:
                <span class="secondary_3--text">
                  {{ helper.abbreviateNumber(helper.convertToBigNumber(latestGlobalMetrics.eth_dominance, 2)) }}
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="item-list" @click="gotoUrl(`/global-metrics`, 'cryptos')">
              <v-list-item-title class="text-center secondary--text">
                {{ $t('Cryptos') }}:
                <span class="secondary_3--text">
                  {{ helper.abbreviateNumber(latestGlobalMetrics.active_cryptocurrencies) }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="flex-grow-0 d-none d-md-flex">
          <v-row dense align="center">
            <v-col
              cols="6"
              sm="7"
              md="9"
              lg="8"
              class="flex-grow-1 text-right"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    :ripple="false"
                    class="no-hover-bg no-bg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :class="`bcm-green-bg padded icon-${ selected_currency.toLowerCase() }`" />
                    <span class="ml-2 secondary--text">{{ selected_currency }}</span>
                    <v-icon x-small class="mt-1 ml-2 secondary--text icon-arrow-head-down" />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in currencies"
                    :key="item.value"
                    @click="onSelectCurrency(item)"
                  >
                    {{ item.currency }}
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn icon small class="mr-2" @click="onChangeColor">
                <v-icon :class="`icon-${ this.$vuetify.theme.dark ? 'night': 'day'} theme-switch`" />
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              sm="5"
              md="3"
              lg="4"
              class="flex-grow-0"
            >
              <v-btn
                v-if="!isLoggedIn"
                small
                primary
                block
                color="secondary_3"
                class="white--text v-btn--radius"
                @click="gotoUrl('/account/login')"
              >
                {{ `${$t("Login")}` }}
              </v-btn>
              <v-menu v-else open-on-hover :offset-y="true" close-delay="200">
                <template #activator="{ on, attrs }">
                  <v-list-item>
                    <v-list-item-title class="secondary--text" v-bind="attrs" v-on="on">
                      {{ $t("MyAccount") }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list>
                  <v-list-item class="px-2 py-1" @click="gotoUrl('/account/profile')">
                    <v-list-item-title>{{ `${$t("Profile")}` }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-2 py-1" @click="$emit('logout')">
                    <v-list-item-title>{{ `${$t("Logout")}` }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { get, call, commit } from 'vuex-pathify'
import {
  GET_LATEST_GLOBAL_METRICS_REQUEST ,
  GET_HISTORY_GLOBAL_METRICS,
} from '@/store/overview/action'
import helper from '@/utils/helper'
import firebase from 'firebase'

export default {
  name: 'LatestGlobalMetrics',
  props:{
    isLoggedIn: { type: Boolean, default: () => false },
  },
  data: () => ({
    helper: helper,
    globalSearchVisibility: true,
    search: '',
    selected_currency: 'USD',
    currencies: [
      { value: 'USD', currency: 'USD' },
      { value: 'EUR', currency: 'EUR' },
      { value: 'INR', currency: 'INR' },
    ],
  }),
  computed: {
    ...get('overview',['latestGlobalMetrics']),
  },
  watch: {
    '$route.path': {
      handler(path) {
        this.path = path
        if (path.indexOf('account') > -1 ||
        path.indexOf('news') > -1 ||
        path.indexOf('analytics') > -1 ||
        path.indexOf('about') > -1
        ) {
          this.globalSearchVisibility = false
        } else {
          this.globalSearchVisibility = true
        }
      },
      deep: true,
      immediate: true,
    },
    '$store.state.fiat': {
      async handler(value) {
        this.selected_currency = value
      },
      deep: true,
    },
  },
  async beforeMount() {
    await this.GET_LATEST_GLOBAL_METRICS_REQUEST()
  },
  methods: {
    ...call('overview', ['GET_LATEST_GLOBAL_METRICS_REQUEST']),
    onSelectCurrency(item) {
      commit('SET_GLOBAL_FIAT', item.value)
      this.selected_currency = item.value
    },
    async gotoUrl(url, type) {
      commit('overview/SET_ChartDisplayType', type)
      if (url !== this.$route.path) this.$router.push(url)
    },
    onChangeColor() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
  },
}
</script>

<style lang="scss" scoped>
.theme {
  &--dark {
    &.item-list > div,
    &.theme-switch {
      background-color: transparent;
    }
  }
  &--light {
    &.item-list > div,
    &.theme-switch {
      background-color: transparent;
    }
  }
}

.theme-switch {
  font-size: 1rem !important;
  font-weight: bold;
  border-radius: 50%;
}

.item-list {
  > div {
    border-radius: 15px;
    padding: 5px;
  }
}

.padded {
  font-size: 1rem !important;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.v-text-field.search-tbox {
  width: 100%;
  max-width: 480px;
  float: right;
}

.latest-global-m {
  @media (max-width: 900px) {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .v-list {
    height: 42px;
    width: 100%;
    background-color: transparent;
    &-item {
      border-radius: inherit;
      padding: 0 4px;
      &.active {
        border-bottom: 1px solid $white;
      }
      &:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: transparent !important;
      }
      &:hover {
        cursor: pointer;
        color: transparent !important;
      }
      &__title {
        color: $white;
        font-size: 0.8rem !important;
      }
    }
  }
  .v-list-item--link:before {
    background-color: none;
  }
}
</style>
