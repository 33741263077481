<template>

  <v-snackbar
    v-if="notifications.isNotification && notifications.message"
    v-model="notifications.isNotification"
    :timeout="2000"
    :value="notifications.isNotification"
    top
    :color="notifications.notificationType"
    right
    text
  >
    {{ notifications.message }}
    <v-icon
      class="snackbar-icon"
      small
      icon
      @click="notifications.isNotification = false"
    >mdi-close</v-icon>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CSnackBar',
  props: {
    'notificationDetail': { type: Object, default: () => {} },
  },
  data: function () {
    return {
      notifications: this.notificationDetail,
    }
  },
}
</script>
