<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      content-class="custom-dialog"
      max-width="100%"
    >
      <v-card
        outlined
        class="common_background elevation-0 px-sm-12 px-1 mx-auto"
      >
        <v-card-title>
          <v-btn
            icon
            absolute
            right
            class="no-background secondary--text mt-4"
            @click="closeCookieDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="secondary--text">
            We use cookies to offer you a better browsing experience, analyze site traffic, personalize content, and serve targeted advertisements. Read about how we use cookies and how you can control them on our <a target="_blank" href="/privacy-policy">Privacy Policy</a>. If you continue to use this site, you consent to our use of cookies.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            :block="$vuetify.breakpoint.xsOnly"
            class="primary text-capitalize body-2 no-hover-bg"
            @click="closeCookieDialog()"
          >
            <span>{{ $t('OK') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name:'CookieDetails',
  data() {
    return {
      dialog: false,
    }
  },
  mounted() {
    const cookieValue = localStorage.getItem('isCookieDetailsVisible')

    if (cookieValue !== 'undefined' && cookieValue !== 'true') {
      localStorage.setItem('isCookieDetailsVisible', 'true')
      this.dialog = true
    }
  },
  methods: {
    closeCookieDialog() {
      this.dialog = localStorage.getItem('isCookieDetailsVisible') === 'true' ? false : true
    },
  },
}
</script>
