<template>
  <v-footer padless>
    <v-container fluid class="max-width">
      <v-row dense justify="center" class="py-5 px-0 ma-0">
        <v-col cols="12" md="1" class="flex-grow-0 text-center">
          <a href="/" class="logo-small">
            <img
              src="@/assets/images/bcm-logo-only-white.png"
              lazy-src="@/assets/images/logo.png"
              alt="BlockchainMetrics"
            />
          </a>
        </v-col>
        <v-col cols="12" md="3">
          <v-row dense>
            <v-col cols="12" class="text-center text-md-left pb-10 pb-md-0">
              <div class="d-inline-flex">
                <a href="/" class="no-underline">
                  <span class="f-20 font-weight-bold white--text">
                    {{ $t("title") }}
                  </span>
                </a>
                <div class="f-14 mx-1 my-1 font-weight-regular yellow--text">BETA</div>
              </div>
            </v-col>
            <v-col cols="12" class="text-center text-md-left pb-10 pb-md-0">
              <h5 class="white--text">Contact</h5>
              <p class="text-center text-md-left mb-0">
                <a
                  class="no-underline common_text--text"
                  href="mailto:support@blockchainmetrics.com"
                >
                  <span class="f-12 common_text--text">
                    <v-icon class="common_text--text">mdi-email-outline</v-icon>
                    support@blockchainmetrics.com
                  </span>
                </a>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row dense>
            <v-col cols="12" class="text-center text-md-left pb-10 pb-md-0">
              <h4 class="white--text">{{ $t('Important') }}</h4>
            </v-col>
            <v-col cols="12" class="pb-10 pb-md-0">
              <v-row dense>
                <v-col
                  v-for="(item, i) in footerMenu"
                  :key="i"
                  cols="12"
                  md="6"
                  class="text-center text-md-left"
                >
                  <router-link class="f-14 common_text--text no-underline" :to="item.url"> {{ item.name }}</router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row dense class="pa-0">
            <v-col cols="12" class="text-center text-md-left pb-10 pb-md-0">
              <h4 class="white--text">{{ $t('Subscribe') }}</h4>
            </v-col>
            <v-col cols="12" class="text-center text-md-left px-md-0 py-md-4 pb-10 pb-md-0">
              <v-form v-model="isValid">
                <v-text-field
                  v-model="email"
                  type="text"
                  background-color="background elevation-0"
                  :placeholder="$t('Email')"
                  hide-details
                  prepend-inner-icon="mdi-email-outline"
                  solo
                  required
                  clearable
                  :rules="emailRules"
                >
                  <template slot="append">
                    <v-btn
                      color="secondary_3"
                      class="v-size--medium uncapitalize"
                      :disabled="!isValid"
                      @click="onSubscribe()"
                    >
                      Ok!
                    </v-btn>
                  </template>
                </v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider color="grey" />
      <v-row dense align="center" class="pa-0 ma-0 text-center text-md-left">
        <v-col cols="12" md="8">
          <span class="f-12 common_text--text">© 2021 Blockchainmetrics.com. All rights reserved.</span>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="4" class="text-center text-md-left">
          <v-row dense>
            <v-col >
              <template
                v-for="link in links"
              >
                <v-btn
                  :key="link.icon"
                  icon
                  class="mx-1 px-sm-3 grey--text"
                  @click="redirectToExternalUrl(link.link)"
                >
                  <v-icon size="28">{{ link.icon }}</v-icon>
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card class="card_bg3 mx-auto">
        <img
          v-if="!errorMessage"
          height="250"
          src="@/assets/images/subscribe.jpg"
          lazy-src="@/assets/images/subscribe.jpg"
          alt="Welcome to BlockchainMetrics"
        />
        <v-card-text>
          <div v-if="errorMessage" class="text-center pt-6">
            <h4>{{ errorMessage }}</h4>
          </div>
          <div v-else>
            <h3 class="text-center my-3">Thanks for subscribing to BlockchainMetrics!!</h3>
            <h4 class="text-center my-3">
              {{ message }}
              <br />
              What happens next?
            </h4>
            <p>
              Keep an eye on your inbox as we’ll be sending you the best price for the Crypto Currency and you will take the best decision through the BlochchainMetrics.
            </p>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
import { call } from 'vuex-pathify'
import { emailFormatRule } from '@/utils/inputRules'

export default {
  name: 'Footer',
  components: {},
  data() {
    return {
      dialog: false,
      clipped: true,
      path: '/',
      email: '',
      emailRules: [(v) => !!v || 'Email is required', emailFormatRule],
      isValid: true,
      message: '',
      errorMessage: '',
      footerMenu: [
        { name: this.$t('PrivacyPolicy'), url: '/privacy-policy' },
        { name: this.$t('TermsOfUse'), url: '/terms-of-use' },
        { name: this.$t('Disclaimer'), url: '/disclaimer' },
        { name: this.$t('Cookies'), url: '/cookies' },
      ],
      links: [
        {
          icon: 'mdi-telegram',
          link: 'https://t.me/blockchainmetrics',
        },
        {
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/BlockchainMetrics-110364347948580',
        },
        {
          icon: 'mdi-twitter',
          link: 'https://www.twitter.com/BlockchainMet',
        },
        {
          icon: 'mdi-instagram',
          link: 'https://www.instagram.com/blockchainmetrics/',
        },
        {
          icon: 'mdi-linkedin',
          link: 'https://www.linkedin.com/in/blockchain-metrics-39aa471b7',
        },
        {
          icon: 'mdi-youtube',
          link: 'https://www.youtube.com/channel/UC8irpzwmLvtAGnhwp3U7wbQ/videos',
        },
        {
          icon: 'icon-tiktok',
          link: 'https://www.tiktok.com/@blockchainmetrics?lang=en',
        },
      ],
    }
  },
  watch: {
    '$route.path': {
      handler: function (path) {
        this.path = path
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...call('account',['SUBSCRIBE_USER']),
    gotoUrl(url) {
      if (url !== this.$route.path) this.$router.push(url)
    },
    redirectToExternalUrl(url) {
      window.open(url, '_blank')
    },
    async onSubscribe() {
      const params = {
        email: this.email,
      }

      const response = await this.SUBSCRIBE_USER(params)

      if (response && response.status === 201) {
        this.dialog = true
        this.message = response?.data?.detail?.msg
      } else {
        this.dialog = true
        this.errorMessage = 'Couldn\'t create subscription. You have already subscribed to Blockchain Metrics.'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  top: 60px;

  .logo-small {
    img {
      width: 100%;
      @media (max-width: 960px) {
        width: 30%;
      }
    }
  }

  .footer-top {
    min-height: 269px;
    width: 70%;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .footer-bottom {
    min-height: 67px;
    width: 70%;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  &.v-footer {
    background-color: $footer_background !important;
  }
}
</style>
