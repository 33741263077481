import { BigNumber } from 'bignumber.js'
import _ from 'lodash'

// const SI_SUP = ['⁰', '¹', '²', '³', '⁴', '⁵', '⁶', '⁷', '⁸', '⁹']
const SI_SUP = ['⁰', '³', '⁶', '⁹', '¹²', '¹⁵', '¹⁸', '²¹','²⁴','²⁷','³⁰',
  '³³', '³⁶', '³⁹', '⁴²', '⁴⁵', '⁴⁸', '⁵¹', '⁵⁴', '⁵⁷', '⁶⁰', '⁶³', '⁶⁶',
  '⁶⁹', '⁷²','⁷⁵','⁷⁸','⁸¹','⁸⁴','⁸⁷','⁹⁰','⁹³','⁹⁶','⁹⁹','¹⁰⁰','¹³⁰','³⁰³',
]

const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'QUADT', 'QUINT', 'SEXT', 'SEPT', 'OCT', 'N', 'DEC',
  'UND', 'DUOD', 'TRED','QUATTUORD','QUIND','SEXD','SEPTEND','OCTOD','NOVEM','VIGINT','UNVOGINT','DUOVIGINT',
  'TREVIGINT','QUATTUORVIGINT','QUINVIGINT','SEXVIGINT','SEPTENVIGINT', 'OCTOVIGINT', 'NONVIGINT', 'TRIGINT','UNTRIGINT',
  'DUOTRIGINT','GOOGOL','SKEWER\'s Number', 'CENTILLION',
]

const convertToTimeFormat = (minutes) => {
  return prefix(Math.floor(minutes / 60), 2) + ':' + prefix(minutes % 60, 2)
}

const convertUnixTime = (unix, utc = false) => {
  const r = new Date(unix * 1000)
  const dateStr = !utc ? nonUTCDate(r) : utcDate(r)

  return dateStr
}

const convertDateToTimeZone = (date, timezone) => {
  return new Date(date).toLocaleString('en-US', {
    timeZone: `${timezone}`,
  })
}

const nonUTCDate = (date) => {
  return `
    ${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}
    ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)} ${date.getHours() <= 12 ? 'AM' : 'PM'}
  `
}

const utcDate = (date) => {
  return `
    ${date.getUTCFullYear()}/${('0' + (date.getUTCMonth() + 1)).slice(-2)}/${('0' + date.getUTCDate()).slice(-2)}
    ${('0' + date.getUTCHours()).slice(-2)}:${('0' + date.getUTCMinutes()).slice(-2)}:${('0' + date.getUTCSeconds()).slice(-2)} UTC
  `
}

const utcToDate = (date) => {
  return `
    ${date.getUTCFullYear()}/${('0' + (date.getUTCMonth() + 1)).slice(-2)}/${('0' + date.getUTCDate()).slice(-2)}`
}

const abbreviateNumberWithTwoDigit = (number) => {
  return (number && number !== undefined) ? number.toFixed(2) : 0
}

const uniqueArray = (label) => {
  return label.filter((c, index) => {
    return label.indexOf(c) === index
  })
}

const roundDecimalPlaces = (num) => {
  let len = num.toString().split('.')

  len = len[1] ? len[1].length : 0

  const [trueNum] = parseFloat(num).toFixed(20).match(/^-?\d*\.?0*\d{0,2}/)
  let trueLen = trueNum.split('.')

  trueLen = trueLen[1] ? trueLen[1].length : 0

  return len >= 1 && len < 3 ? convertToBigNumber(num, 2) : trueLen === 20 ? convertToBigNumber(num, 2) : trueNum
}

const convertToBigNumber = (num, places) => {
  if (num === '-') {
    return num
  }
  const result = num ? new BigNumber(num) : new BigNumber(0)

  return result.toFormat(places)
}

const prefix = (num, size) => {
  let s = num + ''

  while (s.length < size) s = '0' + s

  return s
}

const abbreviateNumber = (number) => {
  const tier = Math.log10(Math.abs(number)) / 3 | 0

  if (tier === 0) return number

  let suffix = ''

  if ((tier * 3) > 26) {
    const supr = (tier * 3).toString().split('')
    let ss = ''

    supr.forEach((o) => {
      ss += SI_SUP[parseInt(o)]
    })

    suffix = `e${ss}`

  } else {
    suffix = SI_SYMBOL[tier]
  }

  const scale = Math.pow(10, tier * 3)
  const scaled = number / scale

  return `${scaled.toFixed(1)} ${suffix}`
}

const padTrailingZeros = (num, size) => {
  let s = num + ''

  while (s.length < size) s = s + '0'

  return s
}

const isEmptyValues = (value) => {
  return !_.isEmpty(value) || value
}

const detectUrl = (value) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  try {
    const chckStr = JSON.parse(value)

    return value
  } catch {
    if (typeof value === 'string')
      return value.replace(urlRegex, (url) => {
        return `<a href=' ${url} '> ${url} </a>`
      })
    else
      return value
  }
}

const extractEosWaxTokenImgUrl = (value) => {
  const params = new URL(`${document.URL}${value}`).searchParams

  return params.get('url')
}

const sortArrayObjects = (arr, flag, query) => {
  const temp = arr.filter((item) => {
    return item[flag].toLowerCase().indexOf(query.toLowerCase()) >= 0 || item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0
  })

  temp.forEach((elem, index) => {
    if (elem[flag].toLowerCase() === query.toLowerCase()) {
      temp.splice(index, 1)
      temp.unshift(elem)
    }
  })

  return temp
}

const sortSpecial = (arr, flag, query) => {
  const temp = JSON.parse(JSON.stringify(arr))

  temp.forEach((elem, index) => {
    if (elem[flag].toLowerCase() === query.toLowerCase()) {
      temp.splice(index, 1)
      temp.unshift(elem)
    }
  })

  return temp
}

function imgerror(evt) {
  return evt.target.src = require('@/assets/images/no-image.png')
}

function truncateString(str, divisor = 10) {
  if (str.length < divisor) {
    return str
  }

  const ellipsis = '...'
  const charsOnEitherSide = Math.floor(divisor / 2) - ellipsis.length

  return str.slice(0, charsOnEitherSide) + ellipsis + str.slice(-charsOnEitherSide)
}

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.toLowerCase().slice(1)
}

const formatAMPM = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours %= 12
  hours = hours || 12
  minutes = minutes < 10 ? `0${minutes}` : minutes

  const strTime = `${hours}:${minutes} ${ampm}`

  return strTime
}

function datetimeWithMonth(value, days = '') {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const newDate = new Date(value)
  const date = newDate.getDate() + ' ' + monthNames[newDate.getMonth()] + ',' + newDate.getFullYear()

  return  days === '1D' ? formatAMPM(newDate) : date
}

export default {
  convertToTimeFormat,
  convertUnixTime,
  convertDateToTimeZone,
  nonUTCDate,
  utcDate,
  utcToDate,
  roundDecimalPlaces,
  convertToBigNumber,
  prefix,
  abbreviateNumber,
  padTrailingZeros,
  isEmptyValues,
  detectUrl,
  extractEosWaxTokenImgUrl,
  abbreviateNumberWithTwoDigit,
  uniqueArray,
  sortSpecial,
  sortArrayObjects,
  imgerror,
  truncateString,
  capitalizeFirstLetter,
  datetimeWithMonth,
}
