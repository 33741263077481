import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en_messages from './en.json'
// import in_messages from './en'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: en_messages, // set locale messages
  silentFallbackWarn: true,
})

export default i18n
