<template>
  <v-app>
    <CustomSnackBar
      v-if="notificationDetail.isNotification && notificationDetail.message"
      :notification-detail="notificationDetail"
    />
    <v-container fluid class="pa-0">
      <NavBar />
      <v-main justify="center" class="main fill-height--500 px-md-0">
        <router-view/>
      </v-main>
    </v-container>
    <CookieDetails />
    <Footer />
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/molecules/Footer'
import CustomSnackBar from '@/components/shared/CustomSnackBar'
import CookieDetails from '@/components/shared/CookieDetails'
import { get } from 'vuex-pathify'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    CustomSnackBar,
    CookieDetails,
  },
  computed:{
    ...get('overview',['notificationDetail']),
  },
}
</script>

<style lang="scss">
  .main {
    position: relative;
    top: 20px;
    max-width: 1920px!important;
    margin: 0 auto;
  }
  .custom-dialog {
    position: absolute;
    bottom: 0;
    margin: 0!important;
  }
</style>
