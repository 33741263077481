var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-width"},[_c('v-combobox',{ref:("txt" + _vm.inputId),staticClass:"small hollow body-2",attrs:{"id":("txt" + _vm.inputId),"v-model":("txt" + _vm.inputId),"full-width":true,"item-value":"symbol","placeholder":_vm.$t('Search'),"loading":_vm.loading,"items":_vm.list,"cache-items":false,"disable-lookup":true,"no-filter":true,"menu-props":{
      minWidth: this.$vuetify.breakpoint.smAndDown ? '100%' : '320',
      maxWidth: this.$vuetify.breakpoint.smAndDown ? '100%' : '400',
      maxHeight: this.$vuetify.breakpoint.smAndDown ? '80%' : '285',
      backgroundColor: '#ffff',
      closeOnContentClick: true
    },"hide-details":"","dense":"","outlined":""},on:{"keyup":function($event){return _vm.onSearchList($event)},"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"body-2 currency-name"},[_vm._v(_vm._s(item.name))]),_c('small',{staticClass:"ml-1 grey--text"},[_vm._v(_vm._s(item.symbol))])]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item',{staticClass:"px-3 py-0 common_background ma-0",on:{"click":function($event){return _vm.goToCoinDetails('/currencies/'+ item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"secondary--text f-24"},[_vm._v(" "+_vm._s(item.name)+" "),_c('small',{staticClass:"ml-1 grey--text"},[_vm._v(_vm._s(item.symbol))]),_c('small',{staticClass:"mt-1 float-right grey--text"},[_vm._v("#"+_vm._s(item.cmc_rank))])])],1)],1)]}}])},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{staticClass:"icon-search1 white--text"})],1),_c('template',{slot:"append"},[_c('v-icon',{staticClass:"mt-1 icon-arrow-head-down white--text",attrs:{"small":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }