export const OVERVIEW_REQUEST = 'OVERVIEW_REQUEST'
export const CURRENCY_REQUEST = 'CURRENCY_REQUEST'
export const CURRENCY_HISTORY_REQUEST = 'CURRENCY_HISTORY_REQUEST'
export const CURRENCY_ACTIVITY_REQUEST = 'CURRENCY_ACTIVITY_REQUEST'
export const CURRENCY_ACTIVITY_DETAILS_REQUEST = 'CURRENCY_ACTIVITY_DETAILS_REQUEST'
export const DAPP_ACTIVITY_REQUEST = 'DAPP_ACTIVITY_REQUEST'
export const DAPP_ACTIVITY_DETAILS_REQUEST = 'DAPP_ACTIVITY_DETAILS_REQUEST'
export const DROPDOWN_REQUEST = 'DROPDOWN_REQUEST'
export const POST_WATCHLIST_REQUEST = 'POST_WATCHLIST_REQUEST'
export const GET_WATCHLIST_REQUEST = 'GET_WATCHLIST_REQUEST'
export const DELETE_WATCHLIST_REQUEST = 'DELETE_WATCHLIST_REQUEST'
export const GET_WATCHLIST_IDS_REQUEST = 'GET_WATCHLIST_IDS_REQUEST'
export const GET_LATEST_GLOBAL_METRICS_REQUEST = 'GET_LATEST_GLOBAL_METRICS_REQUEST'
export const GET_HISTORY_GLOBAL_METRICS_REQUEST = 'GET_HISTORY_GLOBAL_METRICS_REQUEST'
export const GET_GLOBALSEARCH_REQUEST = 'GET_GLOBALSEARCH_REQUEST'
export const GET_PRICE_GAINERS_REQUEST = 'GET_PRICE_GAINERS_REQUEST'
export const GET_PRICE_LOOSERS_REQUEST = 'GET_PRICE_LOOSERS_REQUEST'
export const GET_ALL_TIME_REQUEST = 'GET_ALL_TIME_REQUEST'
export const GET_EXCHANGE_VOLUME_REQUEST = 'GET_EXCHANGE_VOLUME_REQUEST'
