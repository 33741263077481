import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'
Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 100)
    })
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Index.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/account/Index.vue'),
      redirect: '/account/login',
      children: [{
        path: '/account/login',
        name: 'Login',
        component: () => import('@/views/account/Login.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/account/register',
        name: 'Register',
        component: () => import('@/views/account/Register.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/account/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/account/ForgotPassword.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/account/profile',
        name: 'profile',
        component: () => import('@/views/account/Profile.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      ],
    },
    {
      path: '/currencies/:name',
      name: 'CurrencyDetails',
      component: () => import('@/views/Currencies.vue'),
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/News.vue'),
    },
    {
      path: '/analytics',
      name: 'Analytics',
      component: () => import('@/views/Analytics.vue'),
    },
    {
      path: '/explorer',
      name: 'Explorer',
      component: () => import('@/views/Explorer.vue'),
    },
    {
      path: '/compare',
      name: 'Compare',
      component: () => import('@/views/Compare.vue'),
    },
    {
      path: '/chain-activity',
      name: 'ChainActivity',
      component: () => import('@/views/ChainActivity.vue'),
    },
    {
      path: '/nfts',
      name: 'Nfts',
      component: () => import('@/views/Nfts.vue'),
    },
    {
      path: '/chain-activity/details/:name',
      name: 'ChainActivityDetails',
      component: () => import('@/views/ChainActivityDetails.vue'),
    },
    {
      path: '/dapp-activity',
      name: 'DappActivity',
      component: () => import('@/views/Dapp.vue'),
    },
    {
      path: '/dapp-activity/details/:name/:token',
      name: 'DappActivityDetails',
      component: () => import('@/views/DappDetails.vue'),
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/views/About.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('@/views/PrivacyPolicy.vue'),
    },
    {
      path: '/disclaimer',
      name: 'Disclaimer',
      component: () => import('@/views/Disclaimer.vue'),
    },
    {
      path: '/cookies',
      name: 'Cookies',
      component: () => import('@/views/Cookies.vue'),
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfUse',
      component: () => import('@/views/TermsOfUse.vue'),
    },
    {
      path: '/global-metrics',
      name: 'HistoryGlobalMetricsDetails',
      component: () => import('@/views/HistoryGlobalMetrics.vue'),
    },
    {
      path: '/confirm-subscription',
      name: 'ConfirmSubscription',
      component: () => import('@/views/ConfirmSubscription.vue'),
    },
    {
      path: '/unsubscribe',
      name: 'Unsubscribe',
      component: () => import('@/views/Unsubscribe.vue'),
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () => import('@/views/Blog.vue'),
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/views/404.vue'),
    },
  ], mode: 'history', base: process.env.BASE_URL,
},
)

router.beforeEach((to, from, next) => {
  // check for requireAuth guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // check if not logged in
    if (!firebase.auth().currentUser) {
      // go to login
      // eslint-disable-next-line callback-return
      next({
        path: 'account/login',
        query: {
          redirect: to.fullPath,
        },
      })
    } else {
      // eslint-disable-next-line callback-return
      next()
    }
  } else {
    // eslint-disable-next-line callback-return
    next()
  }
})

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes,
// })

export default router
