// eslint-disable-next-line no-unused-vars

import axios from 'axios'
import AxiosConfig from '@/utils/axiosConfig'
import api from '@/utils/api'
import firebase from 'firebase'
import { dispatch } from 'vuex-pathify'
import {
  GET_FIREBASE_IDTOKEN_REQUEST,
  FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD,
  FIREBASE_CREATEUSER_WITH_EMAIL_PASSWORD,
  FIREBASE_SEND_VERIFICATION_EMAIL,
  LOGOUT,
  LOGIN_WITH_TOKEN,
  FIREBASE_SIGNIN_WITH_GOOGLE,
  FIREBASE_SEND_PASSWORD_RESET_EMAIL,
  SUBSCRIBE_USER,
  CONFIRMSUBSCRIPTION,
  UNSUBSCRIBE_USER,
} from './action'

const state = {
  firebaseUserDetails: {},
  isVerifiedEmailUser: false,
  notificationDetail: {
    message: '',
    pageDetails: '',
    apidetails: '',
    notificationType: '',
    isNotification: false,
    timeout: '2000',
  },
}

const getters = {
  firebaseUserDetails: (state) => state.firebaseUserDetails,
  isVerifiedEmailUser: (state) => state.isVerifiedEmailUser,
  notificationDetail: (state) => state.notificationDetail,
}

const actions = {
  [FIREBASE_SEND_PASSWORD_RESET_EMAIL]: async ({ commit }, payload) => {
    await firebase
      .auth()
      .sendPasswordResetEmail(payload.email)
      .then((response) => {
        if (response) {
          const notificationDetail = {
            message: 'Check your registered email to reset the password!',
            pageDetails: 'ForgetPassword',
            apidetails: 'FIREBASE_SEND_PASSWORD_RESET_EMAIL',
            notificationType: 'success',
            isNotification: true,
          }

          commit('SET_MESSAGE', notificationDetail)
        }
      }, (err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'ForgetPassword',
          apidetails: 'GET_FIREBASE_IDTOKEN_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [GET_FIREBASE_IDTOKEN_REQUEST]: async ({ commit }) => {
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async (idToken) => {
        if (idToken) {
          await dispatch('account/' + LOGIN_WITH_TOKEN, idToken)
        }
      }, (err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'LOGIN',
          apidetails: 'GET_FIREBASE_IDTOKEN_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD]: async ({ commit }, payload) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then(async (response) => {
        if (response) {
          await dispatch('account/' + GET_FIREBASE_IDTOKEN_REQUEST)
        }
      }, (err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'LOGIN',
          apidetails: 'FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [FIREBASE_CREATEUSER_WITH_EMAIL_PASSWORD]: async ({ commit }, payload) => {
    await firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then(async (response) => {
        if (response) {
          await dispatch('account/' + FIREBASE_SEND_VERIFICATION_EMAIL)
          // await dispatch('account/' + GET_FIREBASE_IDTOKEN_REQUEST)
        }
      }, (err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'SignUp',
          apidetails: 'FIREBASE_CREATEUSER_WITH_EMAIL_PASSWORD',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [FIREBASE_SEND_VERIFICATION_EMAIL]: async ({ commit }) => {
    const currentUser = await firebase.auth().currentUser

    if (currentUser && currentUser.emailVerified) {
      commit('SET_VERIFIED_EMAILUSER', currentUser.emailVerified)
      await dispatch('account/' + GET_FIREBASE_IDTOKEN_REQUEST)
    } else {
      currentUser.sendEmailVerification()
        .then(async () => {
          const notificationDetail = {
            message: 'Email verification link has been sent, please verify it and do login.',
            pageDetails: 'SignUp',
            apidetails: '',
            notificationType: 'success',
            isNotification: true,
          }

          commit('SET_MESSAGE', notificationDetail)
        }, (err) => {
          const notificationDetail = {
            message: err.message,
            pageDetails: 'SignUp',
            apidetails: 'FIREBASE_SEND_VERIFICATION_EMAIL',
            notificationType: 'error',
            isNotification: true,
          }

          commit('SET_MESSAGE', notificationDetail)
        })
    }
  },
  [LOGOUT]: async ({ commit }) => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem('token')
        localStorage.removeItem('isWatchListOpened')
        // localStorage.clear()
        commit('SET_FIREBASE_USER_DETAILS', [])
      }, (err) => {
        console.log(err.message)
      })
  },
  [LOGIN_WITH_TOKEN]: async ({ commit }, token) => {
    await axios.post(`${api.apiUrl}login/?id_token=${token}`, AxiosConfig())
      .then(async (response) => {
        if (response) {
          localStorage.setItem('token', JSON.stringify(response.data.token))
          const currentUser = await firebase.auth().currentUser

          if (currentUser && currentUser.emailVerified) {
            commit('SET_VERIFIED_EMAILUSER', currentUser.emailVerified)
            commit('SET_FIREBASE_USER_DETAILS', response.data)
          }
        }
      }).catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'SignUp',
          apidetails: 'LOGIN_WITH_TOKEN',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [FIREBASE_SIGNIN_WITH_GOOGLE]: async ({ commit }, provider) => {
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (response) => {
        if (response) {
          // const { idToken } = response.credential

          await dispatch('account/' + GET_FIREBASE_IDTOKEN_REQUEST)
          // await dispatch('account/' + LOGIN_WITH_TOKEN, idToken)
        }
      }).catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'SignUp',
          apidetails: 'FIREBASE_SIGNIN_WITH_GOOGLE',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [SUBSCRIBE_USER]: async ({ commit }, payload) => {
    return await axios
      .post(
        `${api.apiUrl}subscribe/?email=${payload.email}`,
        AxiosConfig(),
      )
      .then((res) => {
        const notificationDetail = {
          message: res?.data?.detail?.msg,
          pageDetails: 'Home',
          apidetails: 'SUBSCRIBE_USER',
          notificationType: 'Success',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)

        return res
      })
      .catch((error) => {
        return error
      })
  },
  [CONFIRMSUBSCRIPTION]: async ({ commit }, payload) => {
    return await axios
      .post(
        `${api.apiUrl}confirm-subscription/?email=${payload.email}&conf_code=${payload.confirmCode}`,
        AxiosConfig(),
      )
      .then((res) => {
        const notificationDetail = {
          message: res.data.detail.msg,
          pageDetails: 'Home',
          apidetails: 'CONFIRMSUBSCRIPTION',
          notificationType: 'Success',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)

        return res
      })
      .catch((err) => {
        return err
      })
  },
  [UNSUBSCRIBE_USER]: async ({ commit }, payload) => {
    return await axios
      .post(
        `${api.apiUrl}unsubscribe/?email=${payload.email}&conf_code=${payload.confirmCode}`,
        AxiosConfig(),
      )
      .then((res) => {
        if (res) {
          const notificationDetail = {
            message: res.data.detail.msg,
            pageDetails: 'Home',
            apidetails: 'UNSUBSCRIBE_USER',
            notificationType: 'Success',
            isNotification: true,
          }

          commit('SET_MESSAGE', notificationDetail)
        }

        return res
      })
      .catch((err) => {
        return err
      })
  },
}

const mutations = {
  SET_FIREBASE_USER_DETAILS: (state, resp) => {
    state.firebaseUserDetails = JSON.parse(JSON.stringify(resp))
  },
  SET_VERIFIED_EMAILUSER: (state, res) => {
    state.isVerifiedEmailUser = res
  },
  SET_MESSAGE(state, resp) {
    state.notificationDetail = resp
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
