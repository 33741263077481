import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './locales/index'
import firebase from 'firebase'
import './configuration/firebaseInit'

Vue.config.productionTip = false

let app

firebase
  .auth()
  .onAuthStateChanged((user) => {
    if (!app) {
      app = new Vue({
        el: '#app',
        i18n,
        router,
        store,
        vuetify,
        render: (h) => h(App),
      })
    }
  })
