<template>
  <div class="fill-width">
    <v-combobox
      :id="`txt${inputId}`"
      :ref="`txt${inputId}`"
      :v-model="`txt${inputId}`"
      :full-width="true"
      item-value="symbol"
      class="small hollow body-2"
      :placeholder="$t('Search')"
      :loading="loading"
      :items="list"
      :cache-items="false"
      :disable-lookup="true"
      :no-filter="true"
      :menu-props="{
        minWidth: this.$vuetify.breakpoint.smAndDown ? '100%' : '320',
        maxWidth: this.$vuetify.breakpoint.smAndDown ? '100%' : '400',
        maxHeight: this.$vuetify.breakpoint.smAndDown ? '80%' : '285',
        backgroundColor: '#ffff',
        closeOnContentClick: true
      }"
      hide-details
      dense
      outlined
      @keyup="onSearchList($event)"
      @blur="onBlur"
    >
      <template slot="prepend-inner">
        <v-icon class="icon-search1 white--text" />
      </template>
      <template slot="append">
        <v-icon small class="mt-1 icon-arrow-head-down white--text" />
      </template>
      <template slot="selection" slot-scope="{ item }">
        <span class="body-2 currency-name">{{ item.name }}</span>
        <small class="ml-1 grey--text">{{ item.symbol }}</small>
      </template>
      <template slot="item" slot-scope="{ item }" class="pa-0">
        <v-list-item
          class="px-3 py-0 common_background ma-0"
          @click="goToCoinDetails('/currencies/'+ item.id)"
        >
          <v-list-item-content>
            <v-list-item-title class="secondary--text f-24">
              {{ item.name }}
              <small class="ml-1 grey--text">{{ item.symbol }}</small>
              <small class="mt-1 float-right grey--text">#{{ item.cmc_rank }}</small>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import {
  GET_GLOBALSEARCH_REQUEST,
} from '@/store/overview/action'
import { get, call, commit } from 'vuex-pathify'
import helper from '@/utils/helper'

export default {
  props: {
    'inputId': { type: String, default: () => null },
    'show': { type: Boolean, default: () => false },
  },
  data: function () {
    return {
      helper: helper,
      search: '',
      list: [],
      loading: false,
    }
  },
  computed: {
    ...get('overview',['globalSearch']),
    dialogSearch: {
      get: get('searchDialog'),
      set: (value) => commit('SET_SEARCH_DIALOG', value),
    },
  },
  beforeMount() {
    this.getGlobalSearch()
  },
  methods: {
    ...call('overview',['GET_GLOBALSEARCH_REQUEST']),
    goToCoinDetails(path) {
      this.$router.push(path)
      this.dialogSearch = false
    },
    onBlur() {
      setTimeout( () => {
        this.$set(this, 'list', this.globalSearch)
      }, 200)
    },
    onSearchList(ev) {
      let temp = JSON.parse(JSON.stringify(this.globalSearch))
      const query = window[`txt${this.inputId}`].value

      clearTimeout(this.timeout)
      this.timeout = setTimeout( () => {
        temp = helper.sortArrayObjects(JSON.parse(JSON.stringify(this.globalSearch)), 'symbol', query)
        this.list = []
        this.$set(this, 'list', temp)
      }, 400)
    },
    async getGlobalSearch(value) {
      const payload = {
        search: value,
      }

      await this.GET_GLOBALSEARCH_REQUEST(payload)
      this.$set(this, 'list', this.list = this.globalSearch)
    },
  },
}
</script>
