// eslint-disable-next-line no-unused-vars

import axios from 'axios'
import AxiosConfig from '@/utils/axiosConfig'
import * as _axiosConfig from '@/utils/axiosConfig'
import api from '@/utils/api'
import helper from '@/utils/helper'

import {
  CURRENCY_REQUEST,
  CURRENCY_HISTORY_REQUEST,
  CURRENCY_ACTIVITY_REQUEST,
  CURRENCY_ACTIVITY_DETAILS_REQUEST,
  DAPP_ACTIVITY_REQUEST,
  DAPP_ACTIVITY_DETAILS_REQUEST,
  OVERVIEW_REQUEST,
  DROPDOWN_REQUEST,
  POST_WATCHLIST_REQUEST,
  GET_WATCHLIST_REQUEST,
  GET_WATCHLIST_IDS_REQUEST,
  DELETE_WATCHLIST_REQUEST,
  GET_LATEST_GLOBAL_METRICS_REQUEST,
  GET_HISTORY_GLOBAL_METRICS_REQUEST,
  GET_GLOBALSEARCH_REQUEST,
  GET_PRICE_GAINERS_REQUEST,
  GET_PRICE_LOOSERS_REQUEST,
  GET_ALL_TIME_REQUEST,
  GET_EXCHANGE_VOLUME_REQUEST,
} from './action'

const state = {
  overview: [],
  currencyHistory: [],
  currency: {},
  chainActivities: [],
  chainActivityDetail: [],
  dappActivities: [],
  dappActivityDetail: [],
  categories: [],
  chains: [],
  subcategories: [],
  chartlabel: [],
  chartData: [],
  transValueChartData: [],
  userCountChartData: [],
  watchlist: [],
  watchlistIds: [],
  notificationDetail: {
    message: '',
    pageDetails: '',
    apidetails: '',
    notificationType: '',
    isNotification: false,
  },
  latestGlobalMetrics: [],
  historyGlobalMetrics: [],
  assets: [],
  chartDisplayType: '',
  view: '',
  globalSearch: [],
  topGainers: [],
  topLoosers: [],
  allTime: [],
  exchangeVolume: [],
}

const getters = {
  getChartData: (state) => {
    return { name: state.name,
      label: state.chartlabel,
      data: state.chartData,
      transactionValue: state.transValueChartData,
      userCounts: state.userCountChartData,
    }
  },
  overviews: (state) => state.overview,
  currencyHistory: (state) => state.currencyHistory,
  currency: (state) => state.currency,
  chainActivities: (state) => state.chainActivities,
  chainActivityDetail: (state) => state.chainActivityDetail,
  dappActivities: (state) => state.dappActivities,
  dappActivityDetail: (state) => state.dappActivityDetail,
  categories: (state) => state.categories,
  subcategories: (state) => state.subcategories,
  chains: (state) => state.chains,
  watchlist: (state) => state.watchlist,
  watchlistIds: (state) => state.watchlistIds,
  notificationDetail: (state) => state.notificationDetail,
  latestGlobalMetrics: (state) => state.latestGlobalMetrics,
  historyGlobalMetrics: (state) => state.historyGlobalMetrics,
  chartDisplayType: (state) => state.chartDisplayType,
  assets: (state) => state.assets,
  globalSearch: (state) => state.globalSearch,
  getTopGainers: (state) => state.topGainers,
  getTopLoosers: (state) => state.topLoosers,
  getAllTime: (state) => state.allTime,
  getExchangeVolume: (state) => state.exchangeVolume,
  name: (state) => state.name,
}

const actions = {
  [OVERVIEW_REQUEST]: async ({ commit }, payload) => {
    commit('CANCEL_REQUEST')
    await axios
      .get(
        `${api.apiUrl}latest-prices/`,
        { params: payload, cancelToken },
        AxiosConfig(),
      )
      .then((res) => {
        const data = res.data.map((x) => {
          const d = Object.assign({}, x)

          d['watchlist'] = false

          return d
        })

        commit('SET_OVERVIEW', data)
      })
      .catch((err) => {
        if (err && err.message) {
          const notificationDetail = {
            message: err.message,
            pageDetails: 'Overview',
            apidetails: 'OVERVIEW_REQUEST',
            notificationType: 'error',
            isNotification: true,
          }

          commit('SET_TOAST_NOTIFICATION', notificationDetail)
        }
      })
  },
  [CURRENCY_REQUEST]: async ({ commit }, payload) => {
    commit('CANCEL_REQUEST')
    await axios
      .get(api.apiUrl + `coin-info/?id=${payload.id}&currency=${payload.currency}`, AxiosConfig())
      .then((res) => {
        commit('SET_CURRENCY', res.data && res.data[0])
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'CURRENCY_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [CURRENCY_HISTORY_REQUEST]: async ({ commit }, payload) => {
    await axios
      .get(
        `${api.apiUrl}history-prices/`,
        { params: payload, cancelToken },
        AxiosConfig(),
      )
      .then((res) => {
        commit('SET_CURRENCY_HISTORY', res.data)
        commit('SET_CHART_DATA', { type: 'currency', data: res.data })
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'CURRENCY_HISTORY_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [CURRENCY_ACTIVITY_REQUEST]: async ({ commit }, payload) => {
    const setData = !payload.setData ? true : payload.setData

    if (!setData) commit('CANCEL_REQUEST')

    return await axios.get(`${api.apiUrl}latest-chain-activity/`,
      { params: payload, cancelToken },
      AxiosConfig())
      .then((res) => {
        if (setData) commit('SET_CHAIN_ACTIVITIES', res.data)

        return res.data
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'CURRENCY_ACTIVITY_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [CURRENCY_ACTIVITY_DETAILS_REQUEST]: async ({ commit }, payload) => {

    if (!payload.setData) commit('CANCEL_REQUEST')

    return await axios.get(`${api.apiUrl}chain-activity/`,
      { params: payload, cancelToken },
      AxiosConfig())
      .then((res) => {
        const label = [], data = []

        if (!payload.setData) {
          commit('SET_CURRENCY_ACTIVITY_DETAILS', res.data)
          commit('SET_CHART_DATA', { type: 'chain', data: res.data })
        } else {
          res.data.forEach((o, idx) => {
            if (idx < Object.keys(res.data).length - 1) {
              label.push(o.trans_date)
              data.push(o.trans_count)
            }
          })
        }

        return { label: label, data: data }
      }).catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'CURRENCY_ACTIVITY_DETAILS_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [DAPP_ACTIVITY_REQUEST]: async ({ commit }, payload) => {
    commit('CANCEL_REQUEST')
    await axios
      .get(
        `${api.apiUrl}latest-dapp-activity/`,
        { params: payload, cancelToken },
        AxiosConfig(),
      )
      .then((res) => {
        commit('SET_DAPP_ACTIVITIES', res.data)
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'DAPP_ACTIVITY_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [DAPP_ACTIVITY_DETAILS_REQUEST]: async ({ commit }, payload) => {
    commit('CANCEL_REQUEST')
    await axios
      .get(
        `${api.apiUrl}dapp-activity/`,
        { params: payload, cancelToken },
        AxiosConfig(),
      )
      .then((res) => {
        commit('SET_DAPP_ACTIVITY_DETAIL', res.data)
        commit('SET_CHART_DATA', { type: 'dapp', data: res.data })
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'DAPP_ACTIVITY_DETAILS_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [DROPDOWN_REQUEST]: async ({ commit }, payload) => {
    await axios
      .get(
        `${api.apiUrl}dropdown/?dropdown_type=${payload.type}`,
        AxiosConfig(),
      )
      .then((res) => {
        commit(`SET_${payload.type.toUpperCase()}`, res.data)
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'DROPDOWN_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [POST_WATCHLIST_REQUEST]: async ({ commit }, payload) => {
    return await new Promise((resolve, reject) => {
      axios
        .post(
          `${api.apiUrl}watchlist/?currency_id=${payload}`,
          _axiosConfig.AxiosLoggedInConfig(),
        )
        .then((response) => {
          if (response) {
            commit('SET_WATCHLIST', response.data)
            resolve(response)
          }
          resolve(null)
        })
        .catch(({ response }) => {
          reject(response.data)
        })
    })
  },
  [GET_WATCHLIST_REQUEST]: async ({ commit }) => {
    await axios
      .get(`${api.apiUrl}watchlist/`, _axiosConfig.AxiosLoggedInConfig())
      .then((response) => {
        if (response) {
          commit('SET_WATCHLIST', response.data)
        }
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'GET_WATCHLIST_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [DELETE_WATCHLIST_REQUEST]: async ({ commit }, payload) => {
    await axios
      .delete(
        `${api.apiUrl}watchlist/?currency_id=${payload}`,
        _axiosConfig.AxiosLoggedInConfig(),
      )
      .then((response) => {
        if (response) {
          commit('SET_WATCHLIST', response.data)
        }
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'DELETE_WATCHLIST_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [GET_WATCHLIST_IDS_REQUEST]: async ({ commit }) => {
    return await axios
      .get(`${api.apiUrl}watchlist-ids/`, _axiosConfig.AxiosLoggedInConfig())
      .then((response) => {
        if (response) {
          commit('SET_WATCHLIST_IDS', response.data)
        }

        return response
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'GET_WATCHLIST_IDS_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)

        return err
      })
  },
  [GET_LATEST_GLOBAL_METRICS_REQUEST]: async ({ commit }) => {
    await axios
      .get(
        `${api.apiUrl}latest-global-metrics/`,
        AxiosConfig(),
      )
      .then((response) => {
        if (response) {
          commit('SET_LATEST_GLOBALMETRICS', response.data[0])
        }
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'GET_LATEST_GLOBAL_METRICS_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [GET_HISTORY_GLOBAL_METRICS_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}history-global-metrics/?days=${payload.days}`,
        AxiosConfig(),
      )
      .then((response) => {
        commit('SET_HISTORY_GLOBALMETRICS', response.data)
        const label = [], data = [], marketCap = [], btcDominance = [], ethDominance = [], cryptos = []

        response.data.forEach((o, idx) => {
          if (idx < Object.keys(response.data).length - 1) {
            label.push(helper.datetimeWithMonth(o.load_date))
            data.push(o.total_volume_24h)
            marketCap.push(o.total_market_cap)
            btcDominance.push(o.btc_dominance)
            ethDominance.push(o.eth_dominance)
            cryptos.push(o.active_cryptocurrencies)
          }
        })
        const legendsName = ['Total 24h Volume', 'Total Market Capitalization', 'BTC Dominance', 'ETH Dominance', 'Cryptos']

        return {
          label: helper.uniqueArray(label),
          data: data,
          marketCap: marketCap,
          btcDominance: btcDominance,
          ethDominance: ethDominance,
          cryptos: cryptos,
          legendName: legendsName,
        }
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'GET_HISTORY_GLOBAL_METRICS_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [GET_GLOBALSEARCH_REQUEST]: async ({ commit }, param) => {
    await axios
      .get(
        `${api.apiUrl}global-search/?search=`,
        AxiosConfig(),
      )
      .then((response) => {
        if (response) {
          commit('SET_GLOBALSEARCH', response.data)
        }
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'GET_GLOBALSEARCH_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [GET_PRICE_GAINERS_REQUEST]: async ({ commit }, param) => {
    await axios
      .get(
        `${api.apiUrl}price-gainers/`,
        { params: param },
        AxiosConfig(),
      )
      .then((response) => {
        if (response) {
          commit('SET_TOP_PRICE_GAINERS', response.data)
        }
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'GET_PRICE_GAINERS_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [GET_PRICE_LOOSERS_REQUEST]: async ({ commit }, param) => {
    await axios
      .get(
        `${api.apiUrl}price-loosers/`,
        { params: param },
        AxiosConfig(),
      )
      .then((response) => {
        if (response) {
          commit('SET_TOP_PRICE_LOOSERS', response.data)
        }
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'GET_PRICE_LOOSERS_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [GET_ALL_TIME_REQUEST]: async ({ commit }, payload) => {
    await axios
      .get(
        `${api.apiUrl}all-time-high-low/`,
        { params: payload },
        AxiosConfig(),
      )
      .then((res) => {
        commit('SET_ALL_TIME', res.data)
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'GET_ALL_TIME_REQUEST',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
  [GET_EXCHANGE_VOLUME_REQUEST]: async ({ commit }, payload) => {
    await axios
      .get(
        `${api.apiUrl}exchange-volume/`,
        { params: payload },
        AxiosConfig(),
      )
      .then((res) => {
        commit('SET_EXCHANGE_VOLUME', res.data)
      })
      .catch((err) => {
        const notificationDetail = {
          message: err.message,
          pageDetails: 'Overview',
          apidetails: 'SET_EXCHANGE_VOLUME',
          notificationType: 'error',
          isNotification: true,
        }

        commit('SET_MESSAGE', notificationDetail)
      })
  },
}

let source, cancelToken

const mutations = {
  CANCEL_REQUEST() {
    if (source) {
      source.cancel()
      source = null
    }
    source = axios.CancelToken.source()
    cancelToken = source.token
  },
  SET_OVERVIEW(state, resp) {
    state.overview = resp
  },
  SET_CURRENCY: (state, resp) => {
    state.currency = resp
    state.currencyHistory = []
  },
  SET_CURRENCY_HISTORY: (state, resp) => {
    state.currencyHistory = resp
  },
  SET_CHAIN_ACTIVITIES: (state, resp) => {
    state.chainActivities = resp
  },
  SET_CURRENCY_ACTIVITY_DETAILS: (state, resp) => {
    state.chainActivityDetail = resp
  },
  SET_DAPP_ACTIVITIES: (state, resp) => {
    state.dappActivities = resp
  },
  SET_DAPP_ACTIVITY_DETAIL: (state, resp) => {
    state.dappActivityDetail = resp
  },
  SET_CHAIN: (state, resp) => {
    state.chains = resp

    state.chains.forEach((o, idx) => {
      o['selected'] = false
    })

    state.chains.unshift({
      dropdown_type: 'CHAIN',
      dropdown_values: 'ALL',
      selected: true,
    })
  },
  SET_CHAIN_ITEMS: (state, resp) => {
    state.chains = resp
  },
  SET_SELECTED_CHAINS_ITEMS: (state, params) => {
    state.chains[params.index].selected = params.value
  },
  SET_CATEGORY: (state, resp) => {
    state.categories = resp

    state.categories.forEach((o, idx) => {
      o['selected'] = false
    })

    state.categories.unshift({
      dropdown_type: 'CATEGORY',
      dropdown_values: 'ALL',
      selected: true,
    })
  },
  SET_CATEGORY_ITEMS: (state, resp) => {
    state.categories = resp
  },
  SET_SELECTED_CATEGORIES_ITEMS: (state, params) => {
    state.categories[params.index].selected = params.value
  },
  'SET_SUB-CATEGORY': (state, resp) => {
    state.subcategories = resp

    state.subcategories.forEach((o, idx) => {
      o['selected'] = false
    })

    if (state.subcategories[0].dropdown_values !== 'ALL') {
      state.subcategories.unshift({
        dropdown_type: 'SUB-CATEGORY',
        dropdown_values: 'ALL',
        selected: true,
      })
    }
  },
  'SET_SUB-CATEGORY_ITEMS': (state, resp) => {
    state.subcategories = resp
  },
  SET_SELECTED_SUBCATEGORIES_ITEMS: (state, params) => {
    state.subcategories[params.index].selected = params.value
  },
  SET_CHART_DATA: (state, params) => {
    state.chartlabel = []
    state.chartData = []
    state.transValueChartData = []
    state.userCountChartData = []
    state.name = ''

    params.data.forEach((o, idx) => {
      if (params.type === 'currency') {
        state.chartlabel.push(
          `${o.load_date_time.slice(0, 10)} ${o.load_date_time.slice(11, 19)}`,
        )

        // const price =
        //   parseFloat(o.price) < 1000
        //     ? parseFloat(o.price).toFixed(4)
        //     : parseFloat(o.price).toFixed(2)

        state.chartData.push(o.price)
        state.name = o.name
      } else if (params.type === 'chain') {
        if (idx < Object.keys(params.data).length - 1) {
          state.chartlabel.push(o.trans_date)
          state.chartData.push(o.trans_count)
          state.name = o.chain
        }
      } else {
        state.chartlabel.push(o.trans_date)
        state.chartData.push(o.trans_count)
        state.transValueChartData.push(o.trans_value)
        state.userCountChartData.push(o.user_count)
        state.name = o.chain
      }
    })
  },
  SET_WATCHLIST: (state, resp) => {
    state.watchlist = resp
  },
  SET_WATCHLIST_IDS: (state, resp) => {
    if (resp && resp.length) {
      const data = state.overview.map((x) => {
        const d = Object.assign({}, x)

        const item = resp.find((y) => y.buw_coin_id === d.id)

        d['watchlist'] = item ? true : false

        return d
      })

      state.overview = data
    }
    state.watchlistIds = resp
  },
  SET_TOAST_NOTIFICATION(state, resp) {
    state.notificationDetail = resp
  },
  SET_LATEST_GLOBALMETRICS(state, resp) {
    state.latestGlobalMetrics = resp
  },
  SET_HISTORY_GLOBALMETRICS(state, resp) {
    state.historyGlobalMetrics = resp
  },
  SET_ChartDisplayType(state, resp) {
    state.chartDisplayType = resp
  },
  SET_VIEW(state, resp) {
    state.view = resp
  },
  SET_ASSETS(state, resp) {
    state.assets = resp
  },
  SET_GLOBALSEARCH(state, resp) {
    state.globalSearch = resp
  },
  SET_TOP_PRICE_GAINERS(state, resp) {
    state.topGainers = resp
  },
  SET_TOP_PRICE_LOOSERS(state, resp) {
    state.topLoosers = resp
  },
  SET_ALL_TIME(state, resp) {
    state.allTime = resp
  },
  SET_EXCHANGE_VOLUME(state, resp) {
    state.exchangeVolume = resp
  },
  SET_MESSAGE(state, resp) {
    state.notificationDetail = resp
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
