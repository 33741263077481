// eslint-disable-next-line no-unused-vars

import axios from 'axios'
import AxiosConfig from '@/utils/axiosConfig'
import * as _axiosConfig from '@/utils/axiosConfig'
import api from '@/utils/api'
import helper from '@/utils/helper'

import {
  CURRENCY_LIST_REQUEST,
  CURRENCY_REQUEST,
  CURRENCY_HISTORY_REQUEST,
  CHAIN_HISTORY_REQUEST,
  DAPP_LIST_REQUEST,
  DAPP_HISTORY_REQUEST,
} from './action'

const state = {
  currencies: [],
  assets: [],
  chains: [],
  tokens: [],
  dappAssets: [],
  view: '',
}

const getters = {
  currencies: (state) => state.currencies,
  assets: (state) => state.assets,
  getTokens: (state) => state.tokens,
  getDappAssets: (state) => state.dappAssets,
}

const actions = {
  [CURRENCY_LIST_REQUEST]: async ({ commit }, payload) => {
    await axios
      .get(
        `${api.apiUrl}latest-prices/`,
        { params: payload },
        AxiosConfig(),
      ).then((res) => {
        commit('SET_CURRENCIES', res.data)
      }).catch((err) => {
        throw new Error(err)
      })
  },
  [CURRENCY_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}latest-prices/`,
        { params: payload },
        AxiosConfig(),
      ).then((res) => {
        const temp = helper.sortSpecial(res.data, 'symbol', payload.description)

        return temp
      }).catch((err) => {
        throw new Error(err)
      })
  },
  [CURRENCY_HISTORY_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}history-prices/`,
        { params: payload },
        AxiosConfig(),
      )
      .then((res) => {
        const label = [], data = [], price = [], cap = []

        res.data.forEach((o, idx) => {
          if (idx < Object.keys(res.data).length - 1) {
            label.push(helper.datetimeWithMonth(o.load_date_time))
            data.push(o.volume_24h)
            price.push(o.price)
            cap.push(o.market_cap)
          }
        })

        return { label: label, data: data, price: price, cap: cap }
      })
      .catch((err) => {
        throw new Error(err)
      })
  },
  [CHAIN_HISTORY_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}chain-activity/`,
        { params: payload },
        AxiosConfig(),
      ).then((res) => {
        const label = [], data = [], price = [], cap = []

        res.data.forEach((o, idx) => {
          if (idx < Object.keys(res.data).length - 1) {
            label.push(o.trans_date)
            data.push(o.trans_count)
          }
        })

        return { label: label, data: data }
      }).catch((err) => {
        throw new Error(err)
      })
  },
  [DAPP_LIST_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}latest-dapp-activity/`,
        { params: payload },
        AxiosConfig(),
      )
      .then((res) => {
        if (payload.returnResult)
          return res.data
        else {
          commit('SET_DAPP_TOKENS', res.data)

          return true
        }
      })
      .catch((err) => {
        return err
      })
  },
  [DAPP_HISTORY_REQUEST]: async ({ commit }, payload) => {
    return await axios
      .get(
        `${api.apiUrl}dapp-activity/`,
        { params: payload, cancelToken: cancelToken ? cancelToken : null },
        AxiosConfig(),
      )
      .then((res) => {
        const label = [], data = []

        res.data.forEach((o, idx) => {
          if (idx < Object.keys(res.data).length - 1) {
            label.push(o.trans_date)
            data.push(o.trans_count)
          }
        })

        return { label: label, data: data }
      })
      .catch((err) => {
        throw new Error(err)
      })
  },
}

let source, cancelToken

const mutations = {
  CANCEL_REQUEST() {
    if (source) {
      source.cancel()
      source = null
    }
    source = axios.CancelToken.source()
    cancelToken = source.token
  },
  SET_CURRENCIES(state, resp) {
    state.currencies = resp
  },
  SET_ASSETS(state, resp) {
    state.assets = resp
  },
  SET_CHAIN_HISTORY(state, resp) {
    state.chains = resp
  },
  SET_DAPP_TOKENS(state, resp) {
    state.tokens = resp
  },
  SET_DAPP_ASSETS(state, resp) {
    state.dappAssets = resp
  },
  SET_VIEW(state, resp) {
    state.view = resp
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
