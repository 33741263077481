// eslint-disable-next-line no-unused-vars
const state = {
  erc20Tokens: [],
  erc20Txns: [],
  nftTokens: [],
  nftTxns: [],
}

const getters = {
  getErc20Tokens: (state) => {
    return state.erc20Tokens
  },
  getErc20Txns: (state) => {
    return state.erc20Txns
  },
  getNftTokens: (state) => {
    return state.nftTokens
  },
  getNftTxns: (state) => {
    return state.nftTxns
  },
}

const actions = {}

const mutations = {
  CANCEL_REQUEST() {
    if (source) {
      source.cancel()
      source = null
    }
    source = axios.CancelToken.source()
    cancelToken = source.token
  },
  SET_ERC20_TOKENS: (state, resp) => {
    state.erc20Tokens = resp
  },
  ADD_ERC20_TOKEN: (state, resp) => {
    state.erc20Tokens.push(resp)
  },
  SET_ERC20_TXNS: (state, resp) => {
    state.erc20Txns = resp
  },
  SET_NFT_TOKENS: (state, resp) => {
    state.nftTokens = resp
  },
  SET_NFT_TXNS: (state, resp) => {
    state.nftTxns = resp
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
