import axios from 'axios'

const AxiosConfig = (config = axios.defaults) => {
  config.headers['content-type'] = 'application/json'
  config.headers.Authorization =  process.env.VUE_APP_TOKEN

  return config
}

export const AxiosLoggedInConfig = (config = axios.defaults) => {
  config.headers['content-type'] = 'application/json'
  config.headers.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('token'))

  return config
}

export default AxiosConfig
